<!-- locations -->
<template>
  <Dashboard :appData="filterAppData">
    <TopAppBar slot="header" title="Locations">
      <div class="location-select">
        <baseGGSelect v-model="locationVal" :inline="true" placeholder="location" @change="change">
          <md-option
            v-for="(item, index) in locationList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </md-option>
        </baseGGSelect>
      </div>
    </TopAppBar>
  </Dashboard>
</template>

<script>
import { mapState } from "vuex";
import { Ajax } from "@/common";
export default {
  name: `Locations`,
  components: {},
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      rolePermission: (state) => state.user.rolePermission,
    }),
    filterAppData(){
      let dataList = [];
      dataList = this.appData.filter((item) =>
        this.rolePermission.some((item1) => item1 === item.label)
      );
      return dataList;
    }
  },
  data() {
    return {
      locationVal: "",
      locationList: [],
      appData: [
        {
          label: "Classes",
          src: require("@/assets/images/menu/classes.svg"),
          path: "/classes",
        },
        {
          label: "People",
          src: require("@/assets/images/menu/people.svg"),
          path: "/people",
        },
          {
          label: "Attendance",
          src: require("@/assets/images/menu/attendance.svg"),
          path: "/attendance",
        },
        {
          label: "Commbook",
          src: require("@/assets/images/menu/commbook.svg"),
          path: "/commbook",
        },
        {
          label: "Announcements",
          src: require("@/assets/images/menu/announcement.svg"),
          path: "/announcement",
        },
        {
          label: "Moments",
          src: require("@/assets/images/menu/moments.svg"),
          path: "/moments",
        },
        {
          label: "Journal",
          src: require("@/assets/images/menu/journal.svg"),
          path: "/journal",
        }

      ],
    };
  },
  methods: {
    getLocation() {
      let params = {
        userId: this.userId,
      };

      Ajax.get("/usermanage/location/selectLocationByUserId", params).then(
        (res) => {
          this.locationList = res.data;

          if (this.locationList.length == 0) {
            this.locationVal = "";
          } else {
            this.locationVal = this.locationList[0].id;
			let schoolName = this.locationList[0].name;
			if(sessionStorage.getItem('lastChoiceLocation')){
			this.locationVal=sessionStorage.getItem('lastChoiceLocation')
			schoolName = this.locationList.find(item => item.id === this.locationVal).name;
			}
            this.$store.commit("SET_SCHOOLNAME", schoolName);
			
          }
        }
      );
    },
    change(val){
      let schoolName = this.locationList.find(item => item.id === val).name;
	  sessionStorage.setItem(
	    "lastChoiceLocation",
	    val
	  );
      this.$store.commit("SET_LOCATIONID",val)
      this.$store.commit("SET_SCHOOLNAME", schoolName);
    }
  },
  created() {
    this.getLocation();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>